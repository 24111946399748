'use client'

import { useEffect } from 'react'
import { type ModalComponent } from '@locmod/modal'
import { ContractFunctionExecutionError } from 'viem'
import { isUserRejectedRequestError, constants } from 'helpers'

import { Icon } from 'components/ui'
import InfoModal, { type InfoModalProps } from 'compositions/app/modals/InfoModal/InfoModal'

import messages from './messages'


type ErrorModalProps = PartialBy<Pick<InfoModalProps, 'title' | 'text' | 'buttonProps'>, 'title'> & {
  error?: any
  withLogger?: boolean
}

const ErrorModal: ModalComponent<ErrorModalProps> = (props) => {
  const { closeModal, error, withLogger = true } = props

  let errorText = ''

  const isRejected = isUserRejectedRequestError(error)

  // display contract revert error in dev and preprod (add prod with adding sentry error code)
  if (!isRejected && !constants.isProd && error instanceof ContractFunctionExecutionError) {
    errorText = error.shortMessage

    if (error.metaMessages?.[0]) {
      errorText += `\n${error.metaMessages?.[0]}`
    }
  }

  useEffect(() => {
    if (isRejected) {
      closeModal()
    }

    if (error && withLogger) {
      console.error(error)
    }
  }, [])

  return (
    <InfoModal
      icon={<Icon className="text-accent-red size-10" name="interface/alert-circle" />}
      title={messages.title}
      text={{ ...messages.text, values: { errorText } }}
      {...props}
    />
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ ErrorModal: typeof ErrorModal }> {}
}

export default ErrorModal
