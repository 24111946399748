'use client'
import type { ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import type { ModalComponent } from '@locmod/modal'
import { Message } from '@locmod/intl'

import { PlainModal } from 'components/feedback'
import { Button, buttonMessages, type ButtonProps, type AppSizedProps } from 'components/inputs'


export type InfoModalProps = {
  children?: ReactNode
  bottomChildren?: ReactNode
  className?: string
  contentClassName?: string
  icon: ReactElement
  title: Intl.Message | string
  text?: Intl.Message | string
  bottomText?: Intl.Message | string
  buttonProps?: Omit<ButtonProps, 'styling' | 'size'> & { styling?: AppSizedProps['styling'] }
  closeOnButtonClick?: boolean // TODO create common modal instead (with icon, title, text, button); InfoModal should close on button click - added on 6/10/22 by pavelivanov
  overlayClosable?: boolean
  onClose?: () => void
}

const InfoModal: ModalComponent<InfoModalProps> = (props) => {
  const {
    closeModal, children, bottomChildren, className, contentClassName,
    icon, title, text, closeOnButtonClick = true,
    overlayClosable = true, buttonProps,
  } = props

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (typeof buttonProps?.onClick === 'function') {
      buttonProps.onClick(event)
    }

    if (closeOnButtonClick) {
      handleClose()
    }
  }

  const handleClose = () => {
    closeModal(true)
  }

  return (
    <PlainModal
      className={clsx(className, 'font-poppins font-medium')}
      closeModal={handleClose}
      overlayClosable={overlayClosable}
    >
      <div className={clsx('text-center flex items-center flex-col', contentClassName)}>
        <div className="h-16 w-16 flex items-center justify-center rounded-full bg-bg-10">
          {icon}
        </div>
        <Message
          className="mt-4 text-body-22 font-semibold"
          value={title}
          html
        />
        {
          Boolean(text) && (
            <Message
              className="mt-4 text-body-16-p text-grey-70"
              value={text}
              html
            />
          )
        }
        {children}
        <Button
          {...buttonProps}
          title={buttonProps?.title || buttonMessages.okay}
          styling={buttonProps?.styling || 'primary'}
          className={clsx('mt-6 w-full', buttonProps?.className)}
          size={44}
          onClick={handleClick}
        />
        {bottomChildren}
      </div>
    </PlainModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ InfoModal: typeof InfoModal }> {}
}

export default InfoModal
