export default {
  title: {
    en: 'Oops..',
  },
  text: {
    en:
      'Something went wrong. Please try again later' +
      '<div class="empty:hidden mt-4 py-2.5 px-3 rounded-4 border border-solid border-grey-90/6 text-body-14-p text-accent-red bg-accent-red-10 whitespace-pre-line">{errorText}</div>',
  },
}
